import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Card from '../../UI/Card';
import { CardsContainer } from '..';

const ParentCards = () => (
  <StaticQuery
    query={graphql`
      query ParentCards {
        image1: file(relativePath: { eq: "graphic-select@2x.png" }) {
          ...focusedImage
        }
        image2: file(relativePath: { eq: "graphic-answer@2x.png" }) {
          ...focusedImage
        }
        image3: file(relativePath: { eq: "graphic-review@2x.png" }) {
          ...focusedImage
        }
      }
    `}
    render={(data) => (
      <CardsContainer>
        <div>

        <Card
          variant="focused"
          image={data.image1.childImageSharp.gatsbyImageData}
          title="View"
          content="View all assessments assigned to your child"
          />
        </div>
        <div>

        <Card
          variant="focused"
          image={data.image2.childImageSharp.gatsbyImageData}
          title="Review"
          content="Review completed and marked assessments"
          />
        </div>
        <div>

        <Card
          variant="focused"
          image={data.image3.childImageSharp.gatsbyImageData}
          title="Analyze"
          content="Analyze results and show class statistics"
          />
        </div>
      </CardsContainer>
    )}
  />
);

export default ParentCards;

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Card from '../../UI/Card';
import { CardsContainer } from '..';

const StudentCards = () => (
  <StaticQuery
    query={graphql`
      query StudentCards {
        image1: file(relativePath: { eq: "graphic-select@2x.png" }) {
          ...focusedImage
        }
        image2: file(relativePath: { eq: "graphic-monitor.png" }) {
          ...focusedImage
        }
        image3: file(relativePath: { eq: "graphic-analyze.png" }) {
          ...focusedImage
        }
      }
    `}
    render={(data) => (
      <CardsContainer>
        <div>

        <Card
          variant="focused"
          image={data.image1.childImageSharp.gatsbyImageData}
          title="View"
          content="View your teachers and the assessments assigned to you"
          />
          </div>
        <div>

        <Card
          variant="focused"
          image={data.image2.childImageSharp.gatsbyImageData}
          title="Answer"
          content="Start your assessment and answer the questions"
          />
          </div>
        <div>

        <Card
          variant="focused"
          image={data.image3.childImageSharp.gatsbyImageData}
          title="Review"
          content="Review your answers before completing the assessment"
          />
          </div>
      </CardsContainer>
    )}
  />
);

export const focusedImage = graphql`
  fragment focusedImage on File {
    childImageSharp {
      gatsbyImageData(width: 130)
    }
  }
`;

export default StudentCards;

import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Masthead from '../components/Platform/Masthead';
import { Container, SectionTitle } from '../components/UI';
import TeacherCards from '../components/Platform/TeacherCards';
import FeatureCards from '../components/Platform/FeatureCards';
import StudentCards from '../components/Platform/StudentCards';
import AppStoreBanner from '../components/Platform/AppStoreBanner';
import Pricing from '../components/Platform/PricingSection';
import { css } from '@emotion/react';
import theme from '../theme';
import ParentCards from '../components/Platform/ParentCards';
import { EMOTION_BREAKPOINTS } from '../constants/breakpoints';

const mastheadStyle = css`
  padding-top: 20px;
  padding-bottom: 20px;
  ${EMOTION_BREAKPOINTS.sm} {
    padding-top: 40px;
    padding-bottom: 100px;
  }
`;

const paddingStyle = css`
  padding-top: 20px;
  padding-bottom: 20px;
  ${EMOTION_BREAKPOINTS.sm} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
const appStorePaddingStyle = css`
  padding-top: 20px;
  padding-bottom: 20px;
  ${EMOTION_BREAKPOINTS.sm} {
    padding-top: 150px;
    padding-bottom: 40px;
  }
`;
const greyBg = css`
  background-color: ${theme.colors.lightgrey};
`;
const PlatformPage = () => (
  <Layout showLogInLink>
    <Seo
      title="Platform"
      keywords={[
        `music lessons app`,
        `educational app`,
        `interactive music appn`,
      ]}
    />
    <Container css={mastheadStyle}>
      <Masthead />
    </Container>
    <div css={greyBg}>
      <Container css={paddingStyle}>
        <SectionTitle>Classed Up is Made for Teachers</SectionTitle>
        <TeacherCards />
      </Container>
    </div>

    <Container css={paddingStyle}>
      <SectionTitle>Features</SectionTitle>
      {/* the carousel inside this component cannot be rendered at buildtime */}
      {typeof window !== undefined && <FeatureCards />}
    </Container>
    <div css={greyBg}>
      <Container css={paddingStyle}>
        <SectionTitle>Classed Up is Made for Students</SectionTitle>
        <StudentCards />
      </Container>
    </div>
    <Container css={paddingStyle}>
      <SectionTitle>Classed Up is Made for Parents</SectionTitle>
      <ParentCards />
    </Container>
    <div css={greyBg}>
      <Container css={paddingStyle}>
        <SectionTitle>Plans</SectionTitle>
        <Pricing />
      </Container>
    </div>
    <Container css={appStorePaddingStyle}>
      <AppStoreBanner />
    </Container>
  </Layout>
);

export default PlatformPage;

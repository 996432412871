import React, { useState, useEffect } from 'react';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { StaticQuery, graphql } from 'gatsby';
import Link from '../../UI/Link';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';

const FeatureContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing[20]} 0;
  p {
    color: ${({ theme }) => theme.colors.text};
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSizes[6]};
    margin: 0;
  }
  a {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
    :visited {
      color: ${({ theme }) => theme.colors.text};
    }
  }
  strong {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 400;
  }
`;

const Dot = styled.div`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ value, id, theme }) =>
    value === id ? theme.colors.blue : theme.colors.white};
`;

const FeaturedText = styled.div`
  flex: 0 1 370px;
  box-sizing: border-box;
  margin-right: 170px;
`;

const FeatureCards = () => {
  const [value, setValue] = useState(0);

  const onChange = (value) => {
    setValue(value);
  };

  const [rendered, setRendered] = useState(false);

  useEffect(() => setRendered(true), [setRendered])

  return (
    <StaticQuery
      query={graphql`
        query FeatureCards {
          image1: file(relativePath: { eq: "feature-1.png" }) {
            ...graphicImage
          }
          image2: file(relativePath: { eq: "feature-2.png" }) {
            ...graphicImage
          }
          image3: file(relativePath: { eq: "feature-3-green@3x.png" }) {
            ...graphicImage
          }
        }
      `}
      // image={data.image1.childImageSharp.gatsbyImageData}
      render={(data) => (
        <div>
          { rendered && (
          <Carousel value={value} onChange={onChange}>
            <FeatureContainer>
              <FeaturedText>
                <p>
                  More than <strong>15 different question types</strong> to
                  create engaging tests
                </p>
                <br />
                <Link to="/features">Learn more</Link>
              </FeaturedText>
              <GatsbyImage
                image={data.image1.childImageSharp.gatsbyImageData}
                alt="Learn more"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <p>
                  View results <strong>graphs</strong> and <strong>charts</strong>, <strong>print</strong> and <strong>email</strong> test results PDFs
                </p>
                <br />
                <Link to="/features">Learn more</Link>
              </FeaturedText>
              <GatsbyImage
                image={data.image2.childImageSharp.gatsbyImageData}
                alt="Learn more"
              />
            </FeatureContainer>
            <FeatureContainer>
              <FeaturedText>
                <p>
                Add a difficulty level to each question to easily create <strong>adaptive tests</strong>
                </p>
                <br />
                <Link to="/features">Learn more</Link>
              </FeaturedText>
              <GatsbyImage
                image={data.image3.childImageSharp.gatsbyImageData}
                alt="Learn more"
              />
            </FeatureContainer>
          </Carousel>
          )}
          <Dots
            css={css`
              button {
                background-color: transparent;
              }
            `}
            value={value}
            onChange={onChange}
            thumbnails={[
              <Dot key={0} id={0} value={value} />,
              <Dot key={1} id={1} value={value} />,
              <Dot key={2} id={2} value={value} />,
            ]}
          />
        </div>
      )}
    />
  );
};

export const graphicImage = graphql`
  fragment graphicImage on File {
    childImageSharp {
      gatsbyImageData(width: 362)
    }
  }
`;

export default FeatureCards;

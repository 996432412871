import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
// import createSvg from '../../../images/graphic-create.svg';
// import monitorSvg from '../../../images/graphic-monitor.svg';
// import analayzeSvg from '../../../images/graphic-analyze.svg';
import Card from '../../UI/Card';
import { CardsContainer } from '..';

const TeacherCards = () => (
  <StaticQuery
    query={graphql`
      query TeacherCards {
        image1: file(relativePath: { eq: "graphic-create.png" }) {
          ...teacherCardImage
        }
        image2: file(relativePath: { eq: "graphic-monitor.png" }) {
          ...teacherCardImage
        }
        image3: file(relativePath: { eq: "graphic-analyze.png" }) {
          ...teacherCardImage
        }
      }
    `}
    render={(data) => (
      <CardsContainer>
        <Card
          variant="focused"
          image={data.image1.childImageSharp.gatsbyImageData}
          title="Create"
          content="Create an assessment, add questions, assign students"
        />
        <Card
          variant="focused"
          image={data.image2.childImageSharp.gatsbyImageData}
          title="Monitor"
          content="View assessment progress as students answer questions"
        />
        <Card
          variant="focused"
          image={data.image3.childImageSharp.gatsbyImageData}
          title="Mark"
          content="Mark and send assessment results to students"
        />
      </CardsContainer>
    )}
  />
);

export const teacherCardImage = graphql`
  fragment teacherCardImage on File {
    childImageSharp {
      gatsbyImageData(width: 130)
    }
  }
`;

export default TeacherCards;

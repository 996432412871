import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Button from '../../UI/Button';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';
import { EMOTION_BREAKPOINTS } from '../../../constants/breakpoints';
import theme from '../../../theme';

const StyledButton = styled(Button)`
font-weight: 400;
font-size: 14px;
width: 156px;
padding: 6px 20px;
${EMOTION_BREAKPOINTS.sm} {
  padding: 14px 20px;
  font-size: 21px;
    width: 272px;

  }
`;

const Section = styled.section`
  position: relative;
  align-items: center;
  h1 {
    font-size: ${({ theme }) => theme.fontSizes[4]};
    font-weight: 400;
    margin-bottom: ${({ theme }) => theme.spacing[1]};
    color: ${({ theme }) => theme.colors.text};
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    color: ${({ theme }) => theme.colors.textGrey};
  }
  display: flex;
  ${EMOTION_BREAKPOINTS.sm} {
    justify-content: space-between;
    h1 {
      font-size: ${({ theme }) => theme.fontSizes[8]};
      margin-bottom: ${({ theme }) => theme.spacing[1]};
    }
    h3 {
      max-width: 370px;
      font-size: ${({ theme }) => theme.fontSizes[4]};
      margin-bottom: 47px;
    }
  }
`;
const Masthead = () => (
  <Section>
    <div style={{ flexBasis: theme.width(5), paddingRight: theme.spacing[4] }}>
      <h1>Get Started for Free</h1>
      <h3>Create engaging iPad assessments for your students</h3>
      <Link to="https://teacher.classedup.com/registration">
        <StyledButton color="blue">Teacher Sign Up</StyledButton>
      </Link>
    </div>
    <div
      css={css`
        overflow: hidden;
        flex: 0 0 526px;
        z-index: 3;
        margin-right: -80%;
        ${EMOTION_BREAKPOINTS.sm} {
          margin-right: 0;
          max-width: 526px;
        }
      `}
    >
      <StaticImage
        src="../../../images/new/hero.svg"
        alt="hero image"
        placeholder="blurred"
        width={526}
        quality={100}
      />
    </div>
  </Section>
);

export default Masthead;

import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { EMOTION_BREAKPOINTS } from '../../../constants/breakpoints';
import AppStore from '../../../images/AppStore.svg';
import { StaticImage } from 'gatsby-plugin-image';
import theme from '../../../theme';

const Container = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  ${EMOTION_BREAKPOINTS.sm} {
    justify-content: space-between;
  }
  align-items: stretch;
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const CallToAction = styled.div`
  text-align: center;
  ${EMOTION_BREAKPOINTS.sm} {
    text-align: right;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  p {
    font-size: 21px;
    color: #8e8e8e;
    font-weight: 400;
    margin: 0;
  }
  a {
    :visited {
      color: ${({ theme }) => theme.colors.text};
    }
    outline: none;
  }
  strong {
    color: ${({ theme }) => theme.colors.text};
    font-size: ${({ theme }) => theme.fontSizes[8]};
    font-weight: 400;
  }
  ${EMOTION_BREAKPOINTS.sm} {
    margin-left: ${({ theme }) => theme.spacing[3]};
    align-items: flex-end
  }
`;

const AppStoreBanner = () => (
  <Container>
    <StaticImage src="../../../images/iPad.svg" alt="ipad" />
    <CallToAction>
      <p style={{ marginBottom: '1em' }}>
        <strong>
          Download the <span style={{ color: theme.colors.red }}>app</span>
        </strong>
        <br />
        Use the iPad App to:
      </p>
      <p>
        Take assessments
        <br />
        View student progress and mark assessments<br />
        Analyze results and show class statistics
      </p>
      <a href="https://apps.apple.com/us/app/classed-up/id1153433683">
        <img
          src={AppStore}
          alt="Download on the App Store"
          css={css`
            width: 150px;
            margin-bottom: 0;
            margin-top: 25px;
            ${EMOTION_BREAKPOINTS.sm} {
              margin-bottom: inherit;
            }
          `}
        />
      </a>
    </CallToAction>
  </Container>
);

export default AppStoreBanner;
